/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Logo } from '../../../images/logos';
import RootState from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { CountdownTimer } from '../CountdownTimer';
import { selectIsCurrentSweepstake } from '../../../slices/sweepstakes/selectors';
import Button from '../../Layout/Button';
import './NavBar.scss';
import useWindowDimensions from '../../../infrastructure/hooks/useWindowDimensions';
import {
  GameRoute,
  GroupIndexRoute,
  GroupListPageRoute,
  LeaderboardRoute,
  ProfileRoute,
} from '../../../constants/Routes';
import { iconShield } from '../../../images/icons';
import { localStorageKey } from '../../../constants';
import GetRouteName from '../../../helpers/GetRouteName';
import { trackEvent } from '../../../infrastructure/tracking/GoogleAnalytics';
import { logout } from '../../../slices/users/actions';
import getProfileImage from '../../../helpers/GetProfileImage';
import classNames from 'classnames';
import logoDesktop from '../../../images/loveuary2024/princess-cruises-nav-horizontal.svg';
import logoMobile from '../../../images/loveuary2024/princess-cruises-nav-vertical.svg';
// import SponsorLogo from '../../SponsorLogo';
import { getNavbarUrl } from './utils';
import SponsorLogo from 'components/SponsorLogo';

const NavBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showHelpModal = useSelector((state: RootState) => state.game.showHelpModal);

  const location = useLocation();
  const { windowWidth } = useWindowDimensions();
  const { name: username, badgeCount, profileImage } = useSelector((state: RootState) => state.user);

  const { entryCount, grandPrizeCount, currentSweepstakeName, nextSweepstakeName, nextSweepstakeStart } = useSelector(
    (state: RootState) => state.sweepstake
  );

  const isCurrentSweepstake = useSelector(selectIsCurrentSweepstake);

  const isNavBarHidden = ['/', '/signup'].includes(location.pathname) && !username;

  const onLogoutClick = () => {
    dispatch(logout());
    try {
      localStorage.removeItem(localStorageKey);
    } catch {}
    const label = GetRouteName(location.pathname, showHelpModal);
    trackEvent('Log out', 'Log out Success', label);
    try {
      if (window.formEntryTimeout) {
        clearTimeout(window.formEntryTimeout);
      }
    } catch {}
    history.push('/');
  };

  if (isNavBarHidden) {
    return null;
  }

  const isTest = true;

  if (isTest) {
    return (
      <>
        <div
          className={`nav-bar nav-bar-${username ? 'logged-in' : 'logged-out'} ${
            username && entryCount ? 'nav-bar-with-entries' : ''
          } ${isCurrentSweepstake || (nextSweepstakeName && nextSweepstakeStart) ? 'nav-bar-with-countdown' : ''}`}
        >
          <div className="nav-bar-link">
            <div className="nav-bar_left">
              <div className="nav-bar_logo-desktop">
                <Link to={'/'}>
                  <Logo
                    type="horizontal"
                    colors={isCurrentSweepstake ? ['#F08E1D', '#652B82'] : ['#F08E1D', '#652B82']}
                  />
                </Link>
                <SponsorLogo breakpointToShow="lg" />
              </div>
              <div className="nav-bar_logo-mobile">
                <Link to={'/'} className="mm-logo-stacked">
                  <Logo type="stacked" colors={isCurrentSweepstake ? ['#F08E1D', '#652B82'] : ['#F08E1D', '#652B82']} />
                </Link>
                <SponsorLogo className="sponsor-logo-mobile" type="stacked" />
                {isCurrentSweepstake ? (
                  <div className="navbar-sponsor-desktop">
                    <p>Presented by</p>
                    <a
                      href="https://www.princess.com?utm_source=matching_momements&utm_campaign=loveuary_sweepstakes"
                      target="_blank"
                      rel="noreferrer noopener"
                      onClick={() => trackEvent('Princess Cruises Nav Bar', 'Princess Cruises Click', 'Navbar')}
                    >
                      <img src={logoDesktop} alt="Princess Cruises" />
                    </a>
                  </div>
                ) : null}
              </div>
              {isCurrentSweepstake ? (
                <div className="navbar-sponsor">
                  <p>Presented by</p>
                  <a
                    href="https://www.princess.com?utm_source=matching_momements&utm_campaign=loveuary_sweepstakes"
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={() => trackEvent('Princess Cruises Nav Bar', 'Princess Cruises Click', 'Navbar')}
                  >
                    <img src={logoMobile} alt="Chex" />
                  </a>
                </div>
              ) : null}
              <div className="nav-bar_entries_container">
                <NavbarLink title="Play" isCurrentRoute={location.pathname === GameRoute} />
                <NavbarLink title="Leaderboard" isCurrentRoute={location.pathname === LeaderboardRoute} />
                <NavbarLink
                  title="Groups"
                  isCurrentRoute={
                    location.pathname === GroupIndexRoute ||
                    location.pathname === GroupListPageRoute ||
                    location.pathname.includes('group')
                  }
                />
                <NavbarLink title="Profile" isCurrentRoute={location.pathname === ProfileRoute} />
              </div>
            </div>
          </div>
          <div className="nav-bar_logout">
            {username && isCurrentSweepstake ? (
              <div className="nav-bar-entries-counter">
                <div>Entries</div>
                <span className={classNames(entryCount > 99 ? 'text-xs' : entryCount > 9 ? 'text-sm' : 'text-base')}>
                  {entryCount}
                </span>
              </div>
            ) : null}

            {username && (
              <>
                <p className="d-none d-sm-inline">
                  Welcome, <span className="text-medium username">{username}</span>
                </p>
                <Link to={ProfileRoute} className="d-inline d-sm-none">
                  {username}
                </Link>
                <div className="profile-picture ">
                  <img src={getProfileImage(profileImage)} alt="" className="img-fluid" />
                </div>
                <div className="earned-badge-container">
                  <img src={iconShield} className="img-fluid icon-shield" alt="" />
                  <span>{badgeCount}</span>
                </div>
                <Button size="sm" className="nav-btn-profile" onClick={() => onLogoutClick()}>
                  Log Out
                </Button>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
  return (
    <div
      className={`nav-bar nav-bar-${username ? 'logged-in' : 'logged-out'} ${
        username && entryCount ? 'nav-bar-with-entries' : ''
      } ${isCurrentSweepstake || (nextSweepstakeName && nextSweepstakeStart) ? 'nav-bar-with-countdown' : ''}`}
    >
      <div className="nav-bar_entries_container nav-bar_entries-desktop">
        {username && isCurrentSweepstake && (
          <>
            <div className="nav-bar_entries">
              <div className="nav-bar_entries-number">
                <p>{entryCount}</p>
              </div>
              <p>
                {currentSweepstakeName && (
                  <>
                    <span className="small uppercase">{currentSweepstakeName}</span>
                    <br />
                  </>
                )}
                Prize
              </p>
            </div>
            <div className="nav-bar_entries">
              <div className="nav-bar_entries-number">
                <p>{grandPrizeCount}</p>
              </div>
              <p>
                {currentSweepstakeName && (
                  <>
                    <span>Grand</span> <br /> <span>Prize</span>
                  </>
                )}
              </p>
            </div>
          </>
        )}
        {isCurrentSweepstake && (windowWidth < 576 || windowWidth >= 1200) && (
          <CountdownTimer className="nav-bar_countdown-desktop" />
        )}
      </div>
      <div className="nav-bar_logo">
        <Link to="/">
          <Logo type="horizontal" colors={isCurrentSweepstake ? ['#802595', '#F4849F'] : ['#F08E1D', '#652B82']} />
        </Link>
        {isCurrentSweepstake && windowWidth >= 576 && windowWidth < 1200 && (
          <CountdownTimer className="nav-bar_countdown-tablet" />
        )}
      </div>
      {username && isCurrentSweepstake && (
        <div className="nav-bar_entries nav-bar_entries-mobile">
          <div className="nav-bar_entries nav-bar_entries-mobile">
            <div className="nav-bar_entries-number">
              <p>{entryCount}</p>
            </div>
            <p>
              {currentSweepstakeName && (
                <>
                  <span className="small uppercase">{currentSweepstakeName}</span>
                  <br />
                </>
              )}
              Prize
            </p>
          </div>
          <div className="nav-bar_entries">
            <div className="nav-bar_entries-number">
              <p>{grandPrizeCount}</p>
            </div>
            <p>
              {currentSweepstakeName && (
                <>
                  <span>Grand</span> <br /> <span>Prize</span>
                </>
              )}
            </p>
          </div>
        </div>
      )}

      <div className="nav-bar_logout">
        {username && (
          <>
            <>
              <p className="d-none d-sm-inline">
                Welcome, <span className="text-medium username">{username}</span>
              </p>
              <Link to={ProfileRoute} className="d-inline d-sm-none">
                <Button variant="link" size="sm" withArrow className="nav-btn-profile-link ">
                  {username}
                </Button>
              </Link>
              <div className="profile-picture ">
                <img src={getProfileImage(profileImage)} alt="" className="img-fluid" />
              </div>
            </>
          </>
        )}
        {username && (
          <>
            <div className="earned-badge-container">
              <img src={iconShield} className="img-fluid icon-shield" alt="shield" />
              <span>{badgeCount}</span>
            </div>
            <>
              <Link to={ProfileRoute} className="d-none d-sm-inline">
                <Button variant="primary" size="sm" className="nav-btn-profile">
                  View Profile
                </Button>
              </Link>
              <Link to={GroupIndexRoute} className="d-none d-sm-inline">
                <Button variant="primary" size="sm" className="nav-btn-profile">
                  Groups
                </Button>
              </Link>
            </>
          </>
        )}
      </div>
    </div>
  );
};

const NavbarLink = ({ title, isCurrentRoute }: { title: string; isCurrentRoute: boolean }) => {
  return (
    <Link
      to={getNavbarUrl(title)}
      className={classNames('nav-bar_menu-item', isCurrentRoute ? 'nav-bar_menu-item--active' : '')}
    >
      <span>{title}</span>
      {isCurrentRoute && <div className="nav-bar_menu-item-indicator" />}
    </Link>
  );
};

export default NavBar;
