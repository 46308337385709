import React from 'react';
import classNames from 'classnames';
import logo from 'images/sponsor-logos/legoland-logo.png';
import { trackEvent } from 'infrastructure/tracking/GoogleAnalytics';

interface SponsorLogoProps {
  type?: 'stacked' | 'horizontal';
  className?: string;
}

const SponsorLogo = ({ type, className }: SponsorLogoProps) => {
  // const isStacked = type === 'stacked';

  return (
    <div className={classNames('landing-header-sponsor-logo', className)}>
      <div className="sponsor-logo-label">Presented by</div>
      <a
        href="https://www.legoland.com/events/llrholidays/?utm_source=matching_moments&utm_medium=logo&utm_campaign=legoland_2024"
        rel="noreferrer noopener"
        target="_blank"
        onClick={() => trackEvent('Legoland Logo', 'Legoland Logo Click', 'Landing')}
      >
        <img src={logo} alt="Legoland" />
      </a>
    </div>
  );
};

export default SponsorLogo;
