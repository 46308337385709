import React from 'react';
// import princessLogo from '../images/PrincessLogo.svg';
import logo from '../images/sponsor-logos/legoland-logo.png';
import useLinkTarget from '../infrastructure/isWebView/useLinkTarget';
import { trackEvent } from '../infrastructure/tracking/GoogleAnalytics';

interface ISponsorLogoProperties {
  className?: string;
  breakpointToShow?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  type?: string;
}
const SponsorLogo = ({ className, breakpointToShow, type }: ISponsorLogoProperties) => {
  const target = useLinkTarget();
  const isStacked = type === 'stacked';

  return (
    <div className={`sponsor-logo ${className} ${isStacked && 'stacked'} `}>
      <div className="sponsor-logo-label-small">Presented by</div>
      <a
        href="https://www.legoland.com/events/llrholidays/?utm_source=matching_moments&utm_medium=logo&utm_campaign=legoland_2024"
        target={target}
        onClick={() => trackEvent('Always Asheville Logo', 'Legoland Logo Click', 'Landing')}
      >
        <img src={logo} alt="Legoland" />
      </a>
    </div>
  );
};

export const SponsorLogoMobile = ({ classNames }: { classNames?: string }) => {
  const target = useLinkTarget();

  return (
    <div className={`sponsor-mobile ${classNames}`}>
      <p>Presented by</p>
      <a
        href="https://www.chex.com/?utm_source=matching_momements&utm_medium=sponsor_logo&utm_campaign=loveuary_sweepstakes"
        target={target}
        onClick={() => trackEvent('Chex Logo', 'Chex Logo Click', 'Landing')}
      >
        <img src={logo} alt="Chex" />
      </a>
    </div>
  );
};

export default SponsorLogo;
