//Limited Edition
export const springintolove = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/springintolove.svg';
export const april2022 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/april2022.svg';
export const may2022 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/may2022.svg';
export const loveclassified = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/loveclassifiedpost.svg';
export const june2022 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/june2022.svg';
export const summernights = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/summernights.svg';
export const july2022 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/july2022.svg';
export const august2022 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/august2022.svg';
export const fallintolove = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/fallintolove.svg';
export const september2022 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/september2022.svg';
export const october2022 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/october2022.svg';
export const november2022 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/november2022.svg';
export const december2022 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/december2022.svg';
export const january2023 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/january2023.svg';
export const february2023 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/february2023.svg';
export const march2023 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/march2023.svg';
export const april2023 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/april2023.svg';
export const may2023 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/may2023.svg';
export const june2023 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/june2023.svg';
export const july2023 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/july2023.svg';
export const august2023 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/august2023.svg';
export const september2023 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/september2023.svg';
export const october2023 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/october2023.svg';
export const november2023 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/november2023.svg';
export const december2023 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/december2023.svg';
export const january2024 = 'https://cdn.si-hallmark.com/matchingmoments/badges/limited/january2024.svg';

//Standard
export const repeatplayer = 'https://cdn.si-hallmark.com/matchingmoments/badges/standard/3-days.svg';
export const weekwarrior = 'https://cdn.si-hallmark.com/matchingmoments/badges/standard/5-days.svg';
export const overachiever = 'https://cdn.si-hallmark.com/matchingmoments/badges/standard/over-achiever.svg';
export const superspeedy = 'https://cdn.si-hallmark.com/matchingmoments/badges/standard/super-speedy.svg';
export const topten = 'https://cdn.si-hallmark.com/matchingmoments/badges/standard/all-time-top-10.svg';
export const weeklytop = 'https://cdn.si-hallmark.com/matchingmoments/badges/standard/weekly-top-5.svg';
export const independent = 'https://cdn.si-hallmark.com/matchingmoments/badges/standard/independent.svg';
export const lastlevelboss = 'https://cdn.si-hallmark.com/matchingmoments/badges/standard/last-level-boss.svg';
export const loyalplayer = 'https://cdn.si-hallmark.com/matchingmoments/badges/standard/loyal-player.svg';
export const centurion = 'https://cdn.si-hallmark.com/matchingmoments/badges/standard/centurion.svg';

export const teamplayer = 'https://cdn.si-hallmark.com/matchingmoments/badges/standard/teamplayer-2x.webp';
export const groupchampions = 'https://cdn.si-hallmark.com/matchingmoments/badges/standard/group-champions-2x.webp';
export const groupgamers = 'https://cdn.si-hallmark.com/matchingmoments/badges/standard/group-gamers-2x.webp';
export const loyalleague = 'https://cdn.si-hallmark.com/matchingmoments/badges/standard/loyal-league-2x.webp';
export const leaderboardlegends =
  'https://cdn.si-hallmark.com/matchingmoments/badges/standard/leaderboard-legends-2x.webp';
